import React from "react"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"

//Image

import pdfLogo from "../images/delinea-image-tool-cyber-insurance-checklist-thumbnail.jpg"
import CyberChecklistContact from "../components/CyberChecklistContact"

const CyberInsuranceChecklist = () => {
  return (
    <Layout>
      <SEO
        title="Cyber Insurance Readiness Checklist"
        description="This cyber insurance checklist guides you through the questions insurance companies are likely to ask when you apply for cyber insurance."
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div
        className="contact-section pdf-ebook-page"
        id="contact"
        // style={{ paddingTop: "120px" }}
      >
        <div className="outer-container" style={{ padding: "0" }}>
          {/* <h2>Contact Us</h2> */}
          <div className="pdf-hero cyber-checklist">
            <div className="blur-wrap">
              <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
                <div style={{ display: "flex" }} className="pdf-hero-wrap">
                  <div
                    style={{
                      display: "flex",
                      // background: "red",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pdfLogo}
                      alt="delinea image tool cyber insurance checklist thumbnail"
                      style={{ width: "70%" }}
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "left",
                        color: "#FFFFFF",
                        marginBottom: "0em",
                      }}
                    >
                      Cyber Insurance Readiness Checklist
                    </h1>
                    <p
                      style={{
                        textAlign: "left",
                        color: "white",
                        fontSize: "x-large",
                      }}
                    >
                      Prepare to answer the questions cyber insurance providers
                      are sure to ask
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row">
            <div
              className="inner-container-2"
              style={{
                justifyContent: "flex-start",
                marginTop: "28px",
                paddingLeft: "80px",
              }}
            >
              <div style={{ display: "flex" }}>
                {/* <img src={Bose} style={{ width: "35%" }}></img> */}
                <h3 style={{ textAlign: "left", textTransform: "none" }}>
                  Cyber incidents are growing and so are rates and requirements
                  of cyber insurance providers.
                </h3>
              </div>
              <p style={{ textAlign: "left" }}>
                Before granting you an insurance policy, insurers want to see
                that you’re proactively managing your cybersecurity program and
                confirm you have effective controls in place to reduce risk. You
                can’t wait until you start shopping for an insurance policy or
                negotiate your renewal to make sure you can answer their
                questions.
              </p>
              <p style={{ textAlign: "left" }}>
                This sample cyber insurance checklist, from Metsi partner
                Delinea, guides you through the top questions most insurance
                companies ask when you apply for cyber insurance, such as:
              </p>
              <ul
                style={{
                  width: "100%",
                  paddingBottom: "30px",
                  textAlign: "left",
                }}
                className="pdf-list"
              >
                <li>
                  How do you address cybersecurity risks, such as unmanaged
                  privileged accounts?
                </li>
                <li>
                  How well do you manage access to critical, sensitive assets?
                </li>
                <li>
                  What is your ability to detect unexpected credential use?{" "}
                </li>
                <li>
                  Do you have an incident response plan in case of a
                  cyberattack?
                </li>
              </ul>
              <p style={{ textAlign: "left" }}>
                Prepare to answer these questions to ensure your company
                qualifies for a robust insurance policy at competitive rates
                that reflect your risk.
              </p>
              {/* <i style={{ fontSize: "x-small", textAlign: "left" }}>
                *Items will be sent once a meeting is accepted, attended and you
                have provided a suitable shipping address. Please allow suitable
                time for shipping
              </i> */}
            </div>
            <div
              className="inner-container-2"
              style={{
                paddingRight: "80px",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  textTransform: "none",
                  width: "100%",
                  paddingLeft: "1.25em",
                  marginTop: "28px",
                  paddingBottom: "0",
                }}
                id="checklist-prompt"
              >
                Get the checklist now.
              </h3>
              <CyberChecklistContact />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CyberInsuranceChecklist
